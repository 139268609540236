@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

@import './variable';
@import './mixin';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0e0d47;
  height: 100vh;
  position: relative;

  .logo {
    width: 150px;
    height: 100px;
  }

  .box {
    gap: 2.813rem;
    width: fit-content;
    left: 41.625rem;
    padding: 0 3rem;
    background: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.938rem;

    @include medium-screen {
      height: 80%;
    }

    @include laptop-screen {
      height: 90%;
    }

    @include computer-screen {
      height: 80%;
    }
  }

  .LoginTypography {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    color: $dark;

    @include laptop-screen {
      font-size: 1.5rem;
    }

    @include computer-screen {
      font-size: 2rem;
    }
  }

  :global(.MuiInputBase-root) {
    border-radius: 15px;
    width: 420px;
    height: 50px;

    .userNameTextfield {
      width: 420px;
      height: 50px;
    }
  }

  .passwordWrapper {
    position: relative;

    .passwordTextField {
      width: 420px;
      height: 50px;
    }

    .errorMsg {
      color: $red;
      font-size: 0.8rem;
      line-height: 0;
      position: absolute;
      margin-bottom: -1rem;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .iconBtn {
    position: absolute;
    right: 1.3rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .BtnLogin {
    width: 26.25rem;
    height: 3.125rem;
    background: #2450b3;
    border-radius: 0.938rem;
  }
}
