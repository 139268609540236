@import './variable';
@import './mixin';

:global(.MuiModal-root) {
  display: grid;
  place-content: center;
}

.modalContent {
  z-index: 999;
  position: relative;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 15rem;
  width: 29rem;
  padding: 0 2.5rem;
  box-sizing: border-box;

  .modalIcon {
    position: absolute;
    width: fit-content;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -3rem;
    border-radius: 100%;
    padding: 0.75rem;

    svg {
      width: 3.75rem !important;
      height: 3.5rem !important;
      color: $white;
    }
  }

  .modalContentNode {
    border-radius: 0;
    height: 60%;
    width: 100%;
    margin-top: 1rem;
  }
}

.logoutModal {
  height: 100%;

  h5 {
    font-weight: 700;
  }

  :global(.MuiTypography-p) {
    text-align: center;
    max-width: 55%;
    font-size: 0.85rem;
  }
}
.errorModal {
  height: 100%;

  h5 {
    font-weight: 700;
  }

  :global(.MuiTypography-p) {
    text-align: center;
    max-width: 75%;
    font-size: 0.85rem;
  }
}

.addLinkModal {
  background-color: $white;
  padding: 3rem 4rem;
  width: 50rem;
  height: 25rem;
  border-radius: 1rem;
  box-sizing: border-box;

  .inputLink {
    input {
      padding: 0.5rem 1rem;
      outline: none;
    }
  }
}

.editFormModal {
  background-color: $white;
  border-radius: 1rem;
  padding: 2rem 3rem;
  box-sizing: border-box;
  width: 65%;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;

  @include medium-screen {
    h4 {
      font-size: 1.2rem;
    }

    :global(.MuiBox-root) {
      margin: 0;
    }
  }

  @include laptop-screen {
    h4 {
      font-size: 1.5rem;
    }
    :global(.MuiBox-root) {
      margin: 1rem;
    }
  }

  @include computer-screen {
    h4 {
      font-size: 2rem;
    }
    :global(.MuiBox-root) {
      margin: auto;
    }
  }

  & > div {
    height: 100%;
  }
}
