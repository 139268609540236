@import './mixin';

.stepFormButtonSize {
  width: 12rem;
}

.buttonRight {
  @include medium-screen {
    button {
      font-size: 0.65rem;
      width: 5rem;
    }
  }

  @include laptop-screen {
    button {
      font-size: 0.7rem;
      width: auto;
    }
  }

  @include computer-screen {
    button {
      width: auto;
      font-size: 1rem;
    }
  }
}

.absoluteBtn {
  position: absolute;
  right: 0;
  bottom: 0;
}

.multiButton {
  position: absolute;
  bottom: 0;
  width: 100%;

  @include medium-screen {
    button {
      font-size: 0.65rem;
      width: 5rem;
    }
  }

  @include laptop-screen {
    button {
      font-size: 0.7rem;
      width: auto;
    }
  }

  @include computer-screen {
    button {
      width: auto;
      font-size: 1rem;
    }
  }
}
