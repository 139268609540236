@import './variable';
@import './mixin';

.stack {
  background-color: $dark-blue;
  height: 100vh;
  box-sizing: border-box;
  text-align: center;

  @include medium-screen {
    padding: 0.15rem;
  }

  @include laptop-screen {
    padding: 0.5rem;
  }

  @include computer-screen {
    padding: 2rem 0.5rem;
  }

  a {
    text-decoration: none;
  }

  p {
    @include medium-screen {
      font-size: 0.65rem !important;
    }

    @include laptop-screen {
      font-size: 0.75rem !important;
    }
  }

  .label {
    color: $white;
    width: 5rem;
    margin-top: 0.5rem;
  }

  .whiteLogo {
    width: 6.25rem;
    min-height: 2rem;

    @include medium-screen {
      width: 5rem;
      height: 2rem;
    }

    @include laptop-screen {
      height: 2.5rem;
    }

    @include computer-screen {
      height: 3rem;
    }
  }

  .iconBtn {
    button {
      color: $white;
      background-color: rgba($gray, 0.3);
    }

    @include medium-screen {
      button {
        padding: 0.4rem;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    @include laptop-screen {
      button {
        padding: 0.5rem;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    @include computer-screen {
      button {
        padding: 0.7rem;
      }
    }
  }

  .iconBtnActive {
    button {
      color: $dark-blue;
      background-color: $white;
    }

    @include medium-screen {
      button {
        padding: 0.4rem;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    @include laptop-screen {
      button {
        padding: 0.5rem;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    @include computer-screen {
      button {
        padding: 0.7rem;
      }
    }
  }

  .logoutBtn {
    color: $white;
    background-color: rgba($gray, 0.3);

    @include medium-screen {
      padding: 0.4rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    @include laptop-screen {
      padding: 0.5rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    @include computer-screen {
      padding: 0.7rem;
    }
  }
}
