.text-input {
  display: flex;
  width: 40%;
  margin: 0;
  padding: 0;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: var(--BORDER-RADIUS);
}

.text-input .prefix-pane {
  display: flex;
  margin: 0;
  padding: 0 0 0 10px;
  align-items: center;
  justify-content: center;
}

.text-input .input-controller {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 8px 10px;
  border: none;
  background-color: transparent;
}

.text-input .input-controller:focus {
  outline: none;
}
