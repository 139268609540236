.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 10px;
  padding: 3px 25px;
  margin: auto;
  color: var(--WHITE);
}

.plain-pending {
  color: var(--WHITE);
  background-color: var(--PENDING);
}
.plain-ongoing {
  color: var(--WHITE);
  background-color: var(--ONGOING);
}
.plain-submitted {
  color: var(--WHITE);
  background-color: var(--SUBMITTED);
}
.plain-rendered {
  color: var(--WHITE);
  background-color: var(--RENDERED);
}
.plain-invoiced {
  color: var(--WHITE);
  background-color: var(--INVOICED);
}
.plain-sent {
  color: var(--WHITE);
  background-color: var(--SENT);
}
.plain-paid {
  color: var(--WHITE);
  background-color: var(--PAID);
}
.plain-complete {
  color: var(--WHITE);
  background-color: var(--COMPLETE);
}
.plain-signed {
  color: var(--WHITE);
  background-color: var(--SIGNED);
}
.plain-rejected {
  color: var(--WHITE);
  background-color: var(--REJECTED);
}

.outline-pending {
  color: var(--PENDING);
  border-color: var(--PENDING);
}
