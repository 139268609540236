@import './variable';
@import './mixin';

.titleSpacing {
  @include laptop-screen {
    padding-bottom: 1rem;

    h3 {
      font-size: 1rem;
    }
  }

  @include computer-screen {
    padding-bottom: 3.75rem;

    h3 {
      font-size: 3rem;
    }
  }
}

:global(.required) {
  color: $red;
}

.form {
  @include medium-screen {
    padding-top: 1rem;

    p {
      font-size: 0.67rem;
      margin-bottom: 0.25rem;
    }
    input,
    :global(.MuiSelect-select) {
      padding: 0.4rem;
    }

    :global(.MuiGrid-root) {
      :global(.MuiGrid-root) {
        padding-top: 1.5rem;
      }
    }
  }

  @include laptop-screen {
    input,
    :global(.MuiSelect-select) {
      padding: 0.5rem;
    }
  }

  @include computer-screen {
    margin-top: 1rem;
    p {
      font-size: 0.85rem;
    }
  }
}

.buttonFormRight {
  position: absolute;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}