@import './variable';
@import './mixin';

.stepFormLayout {
  padding: 3rem 3rem;
  height: 100vh;
  box-sizing: border-box;

  .stepForms {
    position: relative;

    @include medium-screen {
      height: 105%;
      padding-right: 0;
    }

    @include laptop-screen {
      height: 110%;
    }

    @include computer-screen {
      height: 40rem;
      padding-right: 3rem;
    }
  }

  .stepFormSteps {
    display: flex;
    flex-direction: column;

    @include medium-screen {
      padding-top: 3rem;
    }

    @include laptop-screen {
      padding-top: 5.5rem;
    }

    @include computer-screen {
      padding-top: 4rem;
    }

    .activeStep {
      @include step-form-variant(active);
    }

    .disabledStep {
      @include step-form-variant(disabled);
    }

    .completeStep {
      @include step-form-variant(complete);
    }

    .completeStepEdit {
      @include step-form-variant(complete-edit);
    }

    .activeStepEdit {
      @include step-form-variant(active-edit);
    }

    .disabledStepEdit {
      opacity: 0.35;
    }

    :global(.MuiStepConnector-root) {
      display: none;
    }

    :global(.MuiStepLabel-label) {
      width: 8rem;
      font-weight: 400;

      @include laptop-screen {
        font-size: 0.95rem;
      }

      @include computer-screen {
        font-size: 1.2rem;
      }
    }
  }

  .stepFormButton {
    padding: 0.6rem 0;
    width: 11rem;
  }
}

.formWrapper {
  height: 10%;

  @include medium-screen {
    h6 {
      font-size: 0.95rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }

  @include laptop-screen {
    h6 {
      font-size: 1rem;
    }
    h3 {
      font-size: 2rem;
    }
  }

  @include computer-screen {
    h6 {
      font-size: 1.35rem;
    }
    h3 {
      font-size: 3rem;
    }
  }
}
