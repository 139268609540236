$white: #ffffff;
$cream-white: #f7f8fb;
$table-white: #fcfbfb;
$dark: #2e2f30;
$gray: #c9c9c9;
$dark-blue: #0e0d47;
$light-blue: #4a78df;

$orange: #e87e29;
$pink: #c2166a;
$purple: #514fbd;
$green: #169865;
$teal: #1e989a;
$red: #d03c31;
