.input {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
  border-radius: var(--BORDER-RADIUS);
  overflow: hidden;
}

.input .input-controller {
  display: flex;
  padding: 0;
  margin: 0 0 0 10px;
  height: 100%;
  width: 100%;
  border: none;
  font-size: var(--FONT-SIZE-S);
  background-color: transparent;
}
.input .input-controller:focus {
  outline: none;
}

.input .btn-controller {
  display: flex;
  padding: 8px 10px;
  margin: 0;
  border: none;
  background-color: transparent;
}
.input .btn-controller:hover {
  cursor: pointer;
}

.btn-controller .edit-icon {
  font-size: var(--FONT-SIZE-S);
  color: var(--BLUE-DARK);
}

.btn-controller .icon {
  font-size: var(--FONT-SIZE-M);
}
