.notfound {
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  gap: 10px;
  background-color: var(--DARK-BLUE);
}

.notfound .top-pane {
  display: flex;
  flex: 3.5;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.top-pane .icon {
  font-size: var(--ICON-SIZE-S);
  color: var(--WHITE);
}

.notfound .center-pane {
  display: flex;
  flex: 0.75;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.notfound .title {
  font-size: var(--FONT-SIZE-L);
  color: var(--WHITE);
}

.notfound .subtitle {
  font-size: var(--FONT-SIZE-M);
  color: var(--WHITE);
}

.notfound .bottom-pane {
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
