.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
  gap: 10px;
  background-color: transparent;
}

.checkbox:focus {
  outline: none;
}
.checkbox:active {
  opacity: 0.8;
}
.checkbox:hover {
  cursor: pointer;
}

.checkbox .icon-check {
  font-size: var(--FONT-SIZE-L) !important;
  padding: 0;
  margin: 0;
  color: var(--BLUE-DARK);
}

.checkbox .icon {
  font-size: var(--FONT-SIZE-L) !important;
  padding: 0;
  margin: 0;
  color: var(--BLUE-DARK);
}
