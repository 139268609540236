@import './variable';
@import './mixin';

:global(#red-btn) {
  color: $red !important;
}

:global(#blue-btn) {
  color: $light-blue;
}

.filterWithDropdown {
  background-color: $white;

  @include medium-screen {
    padding: 0.55rem 0.75rem;
  }

  @include laptop-screen {
    padding: 0.75rem 1rem;
  }
}

.searchInput {
  background-color: $table-white;
  width: 100%;

  input {
    font-size: 0.85rem;

    @include medium-screen {
      padding: 0.3rem;
    }

    @include laptop-screen {
      padding: 0.4rem;
    }
  }

  svg {
    width: 1.2rem;
  }
}

.dropdown {
  width: 18rem;
  background-color: $table-white;
  font-size: 0.85rem !important;

  @include medium-screen {
    height: 1.75rem;
    padding: 0.1rem 0.5rem;
  }

  @include laptop-screen {
    height: 2.4rem;
  }

  @include computer-screen {
    height: 2rem;
  }

  :global(.MuiSelect-select) {
    @include medium-screen {
      font-size: 0.75rem !important;
      padding: 0 0.5rem;
    }

    @include laptop-screen {
      padding: 0 1rem;
    }

    @include computer-screen {
      padding: 0 1rem;
    }
  }
}

// Filter Overview

.dateTypograpy {
  text-align: center;
  @include medium-screen {
    font-size: 0.55rem !important;
  }

  @include laptop-screen {
    font-size: 0.65rem !important;
  }

  @include computer-screen {
    font-size: 0.7rem !important;
  }
}

.filterOverview {
  background-color: $white;
  padding: 0.75rem 1rem;

  input {
    padding: 0.6rem;
    font-size: 0.85rem;

    @include medium-screen {
      padding: 0.3rem;
    }

    @include laptop-screen {
      padding: 0.4rem;
    }

    @include computer-screen {
      padding: 0.5rem;
    }
  }
  .searchInputOverview {
    width: 35%;
    background-color: $table-white;

    svg {
      @include medium-screen {
        width: 0.9rem;
      }

      @include laptop-screen {
        width: 1rem;
      }

      @include computer-screen {
        width: 1.15rem;
      }
    }
  }
}
