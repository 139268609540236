.date-picker {
}

.date-picker .date {
  padding: 8px 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: var(--FONT-SIZE-S);
  border-radius: var(--BORDER-RADIUS);
  background-color: transparent;
}

.date-picker .date:focus {
  outline: none;
}
