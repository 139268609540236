.list {
  display: block;
  flex-direction: column;
  max-height: 125px;
  overflow-y: auto;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  background-color: var(--WHITE);
  position: absolute;
  z-index: 1000;
}

.list::-webkit-scrollbar {
  width: 7.5px;
}

.list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.list::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.list .item-pane {
  display: flex;
  flex-direction: row;
}

.item-pane .checkbox-pane {
  display: flex;
  padding: 10px 0 10px 10px;
}

.item-pane .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 10px;
  margin: 0;
  font-size: var(--FONT-SIZE-XS);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: none;
  background-color: transparent;
}
.item-pane .item:active {
  opacity: 0.8;
}

.item-pane .item:focus {
  outline: none;
}
.item-pane .item:hover {
  cursor: pointer;
}
