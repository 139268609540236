@import './variable';
@import './mixin';

@mixin tableCellIcons {
  padding: 0;
  margin: auto;
  display: flex;
}

.highlight {
  background-color: $cream-white;
}

.normal {
  background-color: $white;
}

:global(.delete-btn) {
  fill: $red !important;
}

:global(#upload-btn) {
  fill: $light-blue;
}

:global(#gdrive-btn) {
  fill: $green;
}

.tableHead {
  background-color: $dark-blue;
  position: sticky;
  top: 0;
  z-index: 10;

  .tableCellHeader {
    color: $white;
    font-family: 'Work Sans' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 0.85rem;
    align-items: center;
    justify-content: center;

    @include medium-screen {
      font-size: 0.6rem;
      padding: 0;
    }

    @include laptop-screen {
      font-size: 0.75rem;
      padding: 0;
    }

    @include computer-screen {
      padding: 0.25rem 0;
    }
  }

  .sortIcon {
    color: $white !important;
    svg {
      fill: $white;
    }
  }
}

.tableBody {
  .tableCellBody {
    color: $dark;
    border: 0;

    // background-color: $white;
    @include medium-screen {
      padding: 0.1rem 0;
      font-size: 0.6rem;
    }

    @include laptop-screen {
      padding: 0.3rem 0;
      font-size: 0.75rem;
    }

    @include computer-screen {
      padding: 0.25rem 0;
    }

    .toggleIcon {
      background-color: $gray;
      border-radius: 30%;
      padding: 0;
      width: 1.25rem;
      height: 1.25rem;

      svg {
        fill: $dark;
        width: 1rem;
        height: 1rem;
      }
    }

    :global([data-testid='CheckBoxOutlineBlankIcon']) {
      fill: $gray;
    }

    :global(.MuiIconButton-root) {
      padding: 0;
    }

    :global(.MuiCheckbox-root) {
      @include tableCellIcons;
      width: 0.813rem;
      height: 0.813rem;
    }

    :global(#edit-icon) {
      fill: $light-blue;
      @include tableCellIcons;
    }
  }
}

.tableDropdown {
  .tableDropdownColumn {
    width: 10rem;
  }

  th {
    font-weight: 700;
  }

  th,
  td {
    color: $dark;
    border: none;
  }
}

.checkboxCell {
  padding-left: 1.25rem !important;
  padding-right: 0.75rem !important;

  @include medium-screen {
    svg,
    input {
      width: 0.85rem;
      height: 0.85rem;
    }
  }

  @include computer-screen {
    svg,
    input {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

:global(.MuiTablePagination-root) {
  overflow: hidden !important;
}

// .borderCell {
//   border-bottom: 1px solid rgba($gray, 0.35) !important;
// }

.tableWrapper {
  @include medium-screen {
    height: 25rem;
  }

  @include laptop-screen {
    height: 30rem;
  }

  @include computer-screen {
    height: 36rem;
  }
}

.tableWrapperOverview {
  height: 500px !important;

  @include medium-screen {
    height: 20rem;
  }

  @include laptop-screen {
    height: 22rem;
  }

  @include computer-screen {
    height: 22.5rem;
  }
}
