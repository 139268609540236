@import './variable';

@mixin step-form-variant($status) {
  @if $status == complete-edit {
    opacity: 0.15;
    color: $green;

    @include step-form-variant-edit($green);
  } @else if $status == active-edit {
    @include step-form-variant-edit($dark-blue);
  } @else if $status == complete {
    opacity: 0.15;
    span {
      color: $green;
    }

    @include step-form-variant-default($green);
  } @else if $status == active {
    color: $dark-blue !important;

    @include step-form-variant-default($dark-blue);
  } @else if $status == disabled {
    opacity: 0.15;

    @include step-form-variant-default($dark-blue);
  }
}

@mixin step-form-variant-default($color) {
  svg {
    fill: $color;
  }

  :global(.MuiStepLabel-iconContainer) {
    svg {
      @include medium-screen() {
        width: 2rem;
        height: 2rem;
      }

      @include computer-screen() {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
}

@mixin step-form-variant-edit($color) {
  :global(.MuiStepLabel-iconContainer) {
    background-color: $color;
    border-radius: 100%;
    padding-right: 0;
    margin-right: 0.75rem;
    padding: 0.5rem;
  }

  svg {
    fill: $white;

    @include laptop-screen() {
      width: 1rem;
      height: 1rem;
    }

    @include computer-screen() {
      width: 2rem;
      height: 2rem;
    }
  }
}

@mixin medium-screen {
  @media screen and (min-width: 900px) {
    @content;
  }
}

@mixin laptop-screen {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin computer-screen {
  @media screen and (min-width: 1536px) {
    @content;
  }
}
