@import './mixin';

.dashboardLayout {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include medium-screen {
    padding: 1rem 2rem 1rem 1rem;
  }

  @include computer-screen {
    padding: 2rem 4rem 2rem 0;
  }
}

.heading {
  @include medium-screen {
    font-size: 1rem !important;
  }

  @include laptop-screen {
    font-size: 1.25rem !important;
  }

  @include computer-screen {
    font-size: 2rem !important;
  }
}
